.new-company-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-company-div {
  height: 30rem;
  width: 25rem;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  padding: 2rem;
  border-radius: 2rem;
}

.new-company-title {
  font-size: 1.5rem;
}

.form-new-company {
  display: flex;
  flex-direction: column;
}

.label-new-company {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-new-company {
  font-family: "Wix Madefor Display", sans-serif;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 0.2rem;
  height: 1.7rem;
  width: 25rem;
  padding: 0.1rem 0.5rem;
}

.input-new-company:focus,
.input-new-company:active {
  outline: none;
}

.buttons-div-new-company {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}

.discard-new-company,
.save-new-company {
  font-size: 1.2rem;
  font-family: "Wix Madefor Display", sans-serif;
  margin: 2rem 1rem;
  width: 8rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.discard-new-company:hover,
.save-new-company:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}

.discard-new-company {
  background-color: #ec812f;
}

.save-new-company {
  background-color: #6ced26;
}
