.companies-container {
  margin: 0 10%;
  padding: 5rem 0;
  width: 80%;
  font-family: "Wix Madefor Display", sans-serif;
}

.new-company-button {
  width: 25%;
  font-size: 1.2rem;
  border: none;
  height: 2.5rem;
  color: #000;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: "Wix Madefor Display", sans-serif;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-company-button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75);
}

.plus-symbol {
  margin-right: 1rem;
  font-size: 2.5rem;
}

.companies-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
  flex-wrap: wrap;
}

.company-div {
  height: 23rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 2rem;
  width: 30%;
  margin-top: 2rem;
}

.company-title {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2rem 2rem 0 0;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
  color: #ae1e8d;
}

.company-details {
  margin: 0 5%;
  padding: 0.5rem 0;
  width: 90%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.company-details-label {
  width: 40%;
  display: inline-block;
  border-radius: 0.5rem;
  text-align: center;
  background: #fff;
  height: 1.7rem;
  line-height: 1.7rem;
}

.company-details-label-warning {
  width: 40%;
  display: inline-block;
  border-radius: 0.5rem;
  text-align: center;
  background: #e87816;
  height: 1.7rem;
  line-height: 1.7rem;
  color: #fff;
}

.company-details-label-expired {
  width: 40%;
  display: inline-block;
  border-radius: 0.5rem;
  text-align: center;
  background: #dc1c1b;
  height: 1.7rem;
  line-height: 1.7rem;
  color: #fff;
}

.action-buttons-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

.edit-button,
.delete-button {
  width: 40%;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: "Wix Madefor Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
}

.edit-button {
  background-color: #5010a8;
}

.delete-button {
  background-color: #ec812f;
}

.edit-button:hover,
.delete-button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75);
}
