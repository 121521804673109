@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display&display=swap");

.App {
  background: url("https://source.unsplash.com/E8Ufcyxz514/2400x1823") center /
    cover no-repeat fixed;
}

.header {
  height: 8rem;
  width: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}

.logo {
  height: 70%;
  margin-left: 5rem;
}

.title {
  font-size: 3rem;
  letter-spacing: 0.5rem;
  color: #fff;
  margin-right: 5rem;
  text-transform: uppercase;
  font-family: "Wix Madefor Display", sans-serif;
}

.nav-button {
  font-size: 1rem;
  letter-spacing: 0.15rem;
  height: 3rem;
  width: 10rem;
  background-color: #fff;
  border-radius: 1rem;
  color: #ae1e8d;
  border: 2px solid #ae1e8d;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Wix Madefor Display", sans-serif;
}

.nav-button:hover {
  background-color: #ae1e8d;
  color: #fff;
  border: 2px solid transparent;
}

.nav-button-selected {
  font-size: 1rem;
  letter-spacing: 0.15rem;
  height: 3rem;
  width: 10rem;
  background-color: #ae1e8d;
  border-radius: 1rem;
  color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Wix Madefor Display", sans-serif;
}
